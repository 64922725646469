<template>
	
	<el-dialog :title="Num==1?'商城推广码':'微信小程序'" :visible.sync="weiCodeIshow" width="500px" @closed="comeback">
		<!-- <div>{{Num}}</div> -->
		<div v-loading="codeding">
			<!-- <div>{{Num}}</div> -->
			<el-input v-model="inputValue" style="width:300px" disabled></el-input>
			<el-button v-clipboard:copy="inputValue" v-clipboard:success="onCopy" v-clipboard:error="onError">
				复制
			</el-button>

			<img :src="codePicture" alt="" id="codeId" style="width:300px;display: block;margin: 30px auto 0">
			<div style="text-align:center;margin-top: 25px;">
				<span style="color:#409EFF;cursor:pointer;padding:20px 0;display: inline-block; " @click="downloadIamge('img',codePicture)">下载小程序码</span>
			</div>
		</div>
	</el-dialog>

</template>

<script>
	import apiList from '@/api/other';
	import config from '@/config/index'
	export default {
		props: {
			weiCodeData: {
				type: Object
			},
			Num:{
				type:Number,
			},
			weiCodeVisivble: {
				type: Boolean,
				default: () => {
					return false
				}
			}

		},
		created() {
			this.weiCodeIshow = this.weiCodeVisivble
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				codePicture: '',
				inputValue: '',
				weiCodeIshow: false,
				codeding:false,
			}
		},
		watch: {
			weiCodeVisivble(n) {
				this.weiCodeIshow = n
			},
			weiCodeData() {
				this.inputValue =this.weiCodeData.Scene==1?`${this.weiCodeData.Page}`:`${this.weiCodeData.Page}?${this.weiCodeData.Scene}`
				// this.inputValue =this.weiCodeData.Scene?`${this.weiCodeData.Page}?${this.weiCodeData.Scene}`:`${this.weiCodeData.Page}`
				this.getData()

			},
		},
		methods: {
			getRamNumber() {
				var result = '';
				for (var i = 0; i < 16; i++) {
					result += Math.floor(Math.random() * 16).toString(16);
				}
				return result.toUpperCase();
			},
			async getData() {
				try {
					this.codeding=true
					let data = {
						page: this.weiCodeData.Page,
						scene: this.weiCodeData.Scene
					}
					let result = await apiList.mallGetQrCode(data);
					// this.codePicture = JSON.parse(result.Result)[0];
					this.codePicture = result.data;
				} catch (e) {
					console.log('e', e)
				}finally{
					this.codeding=false
				}
			},
			comeback() {
				this.$emit('cancel', false)
			},

			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},

			downloadIamge(selector, name) {
				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')

					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')

					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = name || '下载图片名称'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}

				image.src = document.getElementById('codeId').src


			},
		}
	}
</script>

<style lang="less">
	#btnsavaImg {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		border: 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		outline: 0;
		margin: 0;
		-webkit-transition: .1s;
		transition: .1s;
		font-weight: 500;
		padding: 9px 15px;
		font-size: 14px;
		border-radius: 4px;
		margin: 20px 0;
	}
</style>
